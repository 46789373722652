input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: auto;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 40px transparent inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  -o-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}
#contact-form-container {
  /*display: grid;*/
  /*grid-template-rows: 99% 1%;*/
  line-height: 1em;
  background: rgb(23 46 64);
  /*background: #013243;*/
  -webkit-box-shadow: -2px 0px 5px 0px rgba(0, 0, 0, 0.32);
  box-shadow: -2px 0px 5px 0px rgba(0, 0, 0, 0.32);
  padding: 30px 50px 60px;
  width: 580px;
  position: fixed;
  left: 101%;
  top: 0;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: 4;
  /*height: 100%;*/
  /*height: calc(100% - 120px);*/
  height: calc(100% - 90px);
  overflow-y: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  justify-items: center;
  scrollbar-width: none;
}

#contact-form-container::-webkit-scrollbar {
  width: 0 !important;
}
#contact-form-container.active {
  left: calc(100% - 680px);
}
#js-navbar-toggle {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
}
#contact-form {
  margin: 6em auto 1em auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 7em);
  overflow: -moz-scrollbars-none;
}

.form-top {
  display: flex;
  flex-direction: column;
}
.google-recaptcha-policy {
  margin-top: 2em;
  font-size: 14px;
  color: #BCBEC0;
  position: relative;
  text-align: center;
}
.google-recaptcha-policy a {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}
::-webkit-input-placeholder {
  color: #fff;
  opacity: 0.7;
  font-size: 20px;
  font-weight: 300;
  font-family: 'Roboto';
}
::-moz-placeholder {
  color: #fff;
  opacity: 0.7;
  font-size: 20px;
  font-weight: 300;
  font-family: 'Roboto';
}
:-ms-input-placeholder {
  color: #fff;
  opacity: 0.7;
  font-size: 20px;
  font-weight: 300;
  font-family: 'Roboto';
}
::-ms-input-placeholder {
  color: #fff;
  opacity: 0.7;
  font-size: 20px;
  font-weight: 300;
  font-family: 'Roboto';
}
::placeholder {
  color: #fff;
  opacity: 0.7;
  font-size: 20px;
  font-weight: 300;
  font-family: 'Roboto';
}
textarea {
  resize: none;
}
.text-input {
  padding: 15px 0 15px 5px;
  border: none;
  outline: none;
  border: 0;
  border-bottom: 1px solid;
  -webkit-border-image: -webkit-gradient(linear, left top, right top, from(#4D4D4D), to(#1D6B78)) 1;
  -webkit-border-image: -webkit-linear-gradient(left, #4D4D4D, #1D6B78) 1;
  -o-border-image: -o-linear-gradient(left, #4D4D4D, #1D6B78) 1;
  border-image: -webkit-gradient(linear, left top, right top, from(#4D4D4D), to(#1D6B78)) 1;
  border-image: linear-gradient(to right, #4D4D4D, #1D6B78) 1;
  margin-bottom: 5px;
  color: #fff;
  background-color: transparent;
  width: 100%;
  font-size: 20px;
  font-family: 'Roboto';
}
.button-group {
  /*margin-top: 70px;*/
  margin-top: 2em;
}
.button-group input {
  background-color: transparent;
  font-size: 20px;
  outline: none;
}
.button-group input:hover {
  cursor: pointer;
}
.button-group input#cancel-button {
  border: none;
  color: #fff;
  margin-right: 30px;
  opacity: 0.7;
}
.button-group input#send-button {
  padding: 10px 40px;
  /*color: #FFFC80;*/
  /*border: 1px solid #FFFC80;*/
  color: #fff;
  /*border: 1px solid #fff;*/
  box-shadow: 0px 0px 12px -2px rgba(0,0,0,0.5);
  border-radius: 10px;
  border-width: thin;
  width: 100%;
  transition: background-color .6s ease;
}

.button-group input#send-button:focus,
.button-group input#send-button:hover {
  background: rgb(20, 40, 55);
}

.button-group #send-button {
  padding: 10px 40px;
  /*color: #FFFC80;*/
  /*border: 1px solid #FFFC80;*/
  color: #fff;
  /*border: 1px solid #fff;*/
  /*box-shadow: 0px 0px 12px -2px rgba(0,0,0,0.5);*/
  box-shadow: rgba(0, 0, 0, 0.16) 2px 3px 3px, rgba(0, 0, 0, 0.23) 1px 3px 3px;
  border-radius: 10px;
  border-width: thin;
  width: 100%;
  transition: background-color .6s ease;
  border-style: solid;
  border-color: #101f2b;
}

.button-group #send-button:active,
.button-group #send-button:hover {
  background: rgb(20, 40, 55);
}

.button-group #send-button {
  background-color: transparent;
  font-size: 20px;
  outline: none;
}
.button-group #send-button:hover {
  cursor: pointer;
}
/************** TOGGLE *********************/
.checkbox-group {
  display: -webkit-box;
  display: -ms-flexbox;
  /*display: flex;*/
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-row-gap: 3px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 25px 5px;
  /*border-bottom: 1px solid;*/
  -webkit-border-image: -webkit-gradient(linear, left top, right top, from(#4D4D4D), to(#1D6B78)) 1;
  -webkit-border-image: -webkit-linear-gradient(left, #4D4D4D, #1D6B78) 1;
  -o-border-image: -o-linear-gradient(left, #4D4D4D, #1D6B78) 1;
  border-image: -webkit-gradient(linear, left top, right top, from(#4D4D4D), to(#1D6B78)) 1;
  border-image: linear-gradient(to right, #4D4D4D, #1D6B78) 1;
  /*margin-bottom: 15px;*/
}

.checkbox-group.our-work-form {
  grid-row-gap: 10px;
}
.checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-right: 16px;
  margin-left: 6px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*background-color: #ccc;*/
  /*border: 1px solid rgb(29 107 120);*/
  border: 1px solid #5F7486;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  min-width: 32px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  background-color: white;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  /*background-color: #23A6EF;*/
  background-color: #5F7486;
}
input:focus + .slider {
  -webkit-box-shadow: 0 0 1px #23A6EF;
  box-shadow: 0 0 1px #23A6EF;
}
input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
.label {
  font-size: 16px;
  color: #BCBEC0;
  font-weight: 400;
}

.registration-label {
  font-size: 16px;
  font-weight: 400;
  color: #57EDC9;
}
.registration-label.replay-only {
  color: #FFB858;
  font-weight: 400;
}

.checkbox-title {
  color: #f3f3f3;
  font-weight: 400;
  padding: 15px 0 0 5px;
  line-height: 24px;
  font-size: 20px;
}

.form-top .message-title {
  color: #f3f3f3;
  line-height: 18px;
  font-weight: 400;
  font-size: 16px;
  padding: 25px 0 0 5px;
}

.checkbox-subtitle {
  font-size: 13px;
  line-height: 16px;
  color: #BCBEC0;
  padding: 5px 0 30px 5px;
  font-weight: 300
}

@media all and (max-width: 920px) {
  #contact-form {
    margin: 5em auto 1em auto;
    height: calc(100% - 6em);
  }
}


@media all and (max-width: 680px) {
  #contact-form-container {
    width: calc(100% - 100px);
  }
  #contact-form-container.active {
    left: 0;
  }
}
@media all and (max-width: 620px) {
  #contact-form-container {
    /*padding: 40px;*/
    padding: 40px 25px 50px;
    width: calc(100% - 50px);
  }
  #contact-form-container.active {
    left: 0;
  }

  #contact-form {
    width: 90%;
  }
}

.checkbox-group {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-bottom: 0;
}
.checkbox-group .checkbox {
  /*padding-bottom: 10px;*/
}
.checkbox-group .checkbox .label {
  /*width: 120px;*/
  color: #F2EFED;
  width: 100%;
  line-height: 18px;
}

@media all and (max-width: 550px) {
  .checkbox-group {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-bottom: 0;
  }
  .checkbox-group .checkbox {
    /*padding-bottom: 15px;*/
  }
  .checkbox-group .checkbox .label {
    width: 100%;
    font-size: 15px;
  }
  .checkbox-title {
    font-size: 16px;
    line-height: 18px;
  }
}

@media all and (max-width: 360px) {
  #contact-form-container {
    /*padding: 40px;*/
    padding: 40px 20px 50px;
    width: calc(100% - 40px);
  }

  #contact-form {
    width: 92%;
  }

  .checkbox-group .checkbox .label {
    font-size: 14px;
  }
}
.inp {
  position: relative;
  margin: 10px auto auto;
  width: 100%;
}
.inp .label {
  position: absolute;
  top: 16px;
  left: 1em;
  font-size: 20px;
  font-size: 1em;
  font-family: 'Roboto';
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
label {
  display: inline-block;
}
.inp input,
textarea {
  -webkit-appearance: none;
  width: 100%;
  border: 1px;
  font-family: inherit;
  padding: 0;
  /*height: 48px;*/
  height: 3rem;
  font-size: 20px;
  font-size: 1em;
  font-family: 'Roboto';
  /*border-bottom: 1px solid #c8ccd4;*/
  /*-webkit-border-image: -webkit-gradient(linear, left top, right top, from(#4D4D4D), to(#1D6B78)) 1;*/
  /*-webkit-border-image: -webkit-linear-gradient(left, #4D4D4D, #1D6B78) 1;*/
  /*-o-border-image: -o-linear-gradient(left, #4D4D4D, #1D6B78) 1;*/
  /*border-image: -webkit-gradient(linear, left top, right top, from(#4D4D4D), to(#1D6B78)) 1;*/
  /*border-image: linear-gradient(to right, #4D4D4D, #1D6B78) 1;*/
  /*background: none;*/
  background: rgb(46 66 83) 0% 0% no-repeat padding-box;
  /*border-radius: 0;*/
  border-radius: 10px;
  color: #fff;
  -webkit-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
  padding-left: 1rem;
  box-sizing: border-box;
  border-style: solid;
  border-color: #101f2b;
}
.inp textarea {
  height: 200px !important;
  padding-top: 1em;
  scrollbar-width: thin;
  scrollbar-color: rgba(14, 28, 38, 0.75) rgb(46 66 83);
}

.inp textarea::-webkit-scrollbar {
  width: 8px;
}

.inp textarea::-webkit-scrollbar-track {
  background: rgb(46 66 83);
}
.inp textarea::-webkit-scrollbar-thumb {
  background-color: rgba(14, 28, 38, 0.75);
  border-radius: 6px;
  border: 1px solid rgb(46 66 83);
}

.inp input:not(:placeholder-shown) + span,
textarea:not(:placeholder-shown) + span {
  /*-webkit-transform: translateY(-33px) scale(0.75);*/
  /*-ms-transform: translateY(-33px) scale(0.75);*/
  /*transform: translateY(-33px) scale(0.75);*/
  font-size: 10px;
  /*top: -0.05rem;*/
  top: 0.1em;
  left: 1.5em;
}
.inp input:focus,
textarea:focus {
  /*background: none;*/
  outline: none;
}
.inp input:focus + span,
textarea:focus + span {
  /*-webkit-transform: translateY(-33px) scale(0.75);*/
  /*-ms-transform: translateY(-33px) scale(0.75);*/
  /*transform: translateY(-33px) scale(0.75);*/
  font-size: 10px;
  top: 0.1em;
  left: 1.5em;
}
.inp input:focus + span + .border,
textarea:focus + span + .border {
  /*-webkit-transform: scaleX(1);*/
  /*-ms-transform: scaleX(1);*/
  /*transform: scaleX(1);*/
}

textarea:focus ~ .label,
textarea:not(:placeholder-shown) ~ .label,
textarea:-webkit-autofill ~ .label {
  /*font-size: 10px;*/
  font-size: 10px;
  top: 0.1em;
  left: 1.5em;
}

.validity-error {
  display: none;
  color: #fa721f;
}
.show-err {
  display: inline;
  color: #fa721f;
}
.hide-err {
  display: none;
}

@media screen and (min-width: 3840px){
  #contact-form {
    margin: 5em auto 1em auto;
    height: calc(100% - 6em);
  }
}
